
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';

export const BackButton = ({ }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    return <Button onClick={() => navigate(-1)} variant="secondary">{t('Terug')}</Button>
}

export default BackButton
