import {dataAccess, map, database} from './dataAccess.js'
import {runTransaction, ref, increment, update, serverTimestamp} from "firebase/database";
import { createUser } from "./UsersDA";
const gamesDAO = dataAccess('/boardgames')
const lendingDAO = dataAccess('/lending')
const usersDAO = dataAccess('/users')
const usersContactDAO = dataAccess('/usersContact')


export async function getBoardGames() {
  let results = await gamesDAO.getAll();
  return map(results);
}

export async function getAllUsers() {
  let results = await usersDAO.getAll();
  return map(results);
}

export async function getBoardGame(id) {
  let result = await gamesDAO.getById(id);
  return { ...result, id};
}

export async function getLendingDetails(id) {
  let result = await lendingDAO.getById(id);
  if(!result){
    return {}
  }
  let user = await usersDAO.getById(result.userId);
  return { lending : {...result, id}, user: {...user, id : result.userId}};
}

export async function createBoardGame(game) {
  let createGameResult = await gamesDAO.create(game);

  return {id : createGameResult.key}
}

export async function updateBoardGame(game) {
  await gamesDAO.update(game);
}

export async function deleteBoardGame(id) {
  const lendings = await lendingDAO.filter('gameId', id);
  const updates = {};
  const dbRef = ref(database);
  if(lendings){
    Object.keys(lendings).forEach((lendingId, i) => {
      updates[`lending/${lendingId}`] = null;
    });
  }
  updates[`boardgames/${id}`] = null;
  await update(dbRef, updates);
}

export async function importBoardGames(data) {
  await gamesDAO.importData(data);
}


export async function returnBoardGame(game) {
  console.log('returnBoardGame input ', game);
  const lendingId = game.lending;
  if(lendingId){

    const lending = await lendingDAO.getById(lendingId);
    console.log('returnBoardGame lending ', lending);
    /*
    if(lending){
      await lendingDAO.update({...lending, returned : true, id: lendingId});
    }
    delete game.lending;
    console.log('returnBoardGame updating game ', game);
    await gamesDAO.update(game);

    const user = await usersDAO.getById(lending.userId);
    await usersDAO.update({...user, id: lending.userId, amountLendings : Math.max((user.amountLendings?user.amountLendings:0) - 1, 0)})
    */

    const updates = {};
    const dbRef = ref(database);
    updates[`lending/${lendingId}/returned`] = true;
    updates[`lending/${lendingId}/updatedAt`] = serverTimestamp();
    updates[`boardgames/${game.id}/lending`] = null;
    updates[`boardgames/${game.id}/updatedAt`] = serverTimestamp();
    updates[`users/${lending.userId}/amountLendings`] = increment(-1);
    await update(dbRef, updates);
  }
}

export async function lendBoardGame(game, {name, contact, userId}) {
  console.log('=> lendBoardGame ', game, name, contact);

  if(game.lending){
    await returnBoardGame(game)
  }
  if(!userId){
    userId = await createUser({name, contact});
  }
  const lending = {
    userId,
    gameId : game.id,
    returned : false
  };

  console.log('lendBoardGame - about to create', lending)
  let createLendingResult = await lendingDAO.create(lending);
  console.log('lendBoardGame - createLendingResult key', createLendingResult.key)
  if(createLendingResult && createLendingResult.key){
    const lendingKey = createLendingResult.key;
    /*
    await gamesDAO.update({...game, lending : lendingKey})

    const user = await usersDAO.getById(userId);
    await usersDAO.update({...user, id: userId, amountLendings : (user.amountLendings?user.amountLendings:0) + 1})
  */
    const updates = {};
    const dbRef = ref(database);
    updates[`boardgames/${game.id}/lending`] = lendingKey;
    updates[`users/${userId}/amountLendings`] = increment(1);
    update(dbRef, updates);
  }
}
