
import BootstrapForm from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import { getAllUsers } from "../dataAccess/BoardGameDA";

export const NewUserFields = ({ formData, setFormData, validationError}) => {
    const { t } = useTranslation();

    return <>
    <BootstrapForm.Label htmlFor="inputLenderName">{t('Jouw naam of alias')}</BootstrapForm.Label>
    <BootstrapForm.Control
      type="text"
      id="inputLenderName"
      name="name"
      required={true}
      aria-describedby="lenderHelpBlock"
      value={formData.name}
      onChange={(v) => {setFormData({...formData, name : v.target.value})}}
      isInvalid={validationError.userName?true:false}
    />
    <BootstrapForm.Text id="lenderHelpBlock" muted>
      {t('Jouw naam of alias zodat we weten wie aan te spreken')}
    </BootstrapForm.Text>
    {validationError && validationError.userName && <BootstrapForm.Control.Feedback type="invalid">
      {validationError.userName}
    </BootstrapForm.Control.Feedback>}
    <br/>
    <br/>
    <BootstrapForm.Label htmlFor="inputLenderContact">{t('Wijze van contact')}</BootstrapForm.Label>
    <BootstrapForm.Control
      type="text"
      id="inputLenderContact"
      name="contact"
      required={true}
      aria-describedby="lenderHelpContact"
      value={formData.contact}
      onChange={(v) => {setFormData({...formData, contact : v.target.value})}}
    />
    <BootstrapForm.Text id="lenderHelpContact" muted>
      {t('Hoe je gecontacteerd mag worden, e-mail adres of telefoonnummer')}
    </BootstrapForm.Text>
    </>;
}

export default NewUserFields

export const validateNewUserForm = async (formData) => {
  let {name} = formData;
  if(name){
    //verify user name not in use
    const [users] = await Promise.all([
        getAllUsers()
      ]);
    if(users.find((u) => u.name.trim().toUpperCase() === name.trim().toUpperCase())){
      console.log('User name found', name)
      return {
        userName : 'Gebruikersnaam reeds in gebruik'
      };
    }
  }
  return {ok : true};
}
