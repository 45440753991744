
import { useLoaderData, Form, redirect, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserWithLendings, getBasicUser } from "../dataAccess/UsersDA";
import { returnBoardGame, getBoardGame } from "../dataAccess/BoardGameDA";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {LendingOverview} from '../components/LendingOverview'
import {BackButton} from '../components/BackButton'
import { FaEdit } from "react-icons/fa";
import {getCurrentUser} from '../authentication/auth'

export default function User() {
  const userWithLendings = useLoaderData();
  const { t } = useTranslation();
  if(!userWithLendings)
  return false;

  const lendings = userWithLendings.lendings?{
    [t('Openstaande uitleningen')] : userWithLendings.lendings.filter((l) => !l.returned),
    [t('Geschiedenis')] : userWithLendings.lendings.filter((l) => l.returned).sort((a, b) => a.updatedAt > b.updatedAt?-1:1)
  }:{}

  return <div className="m-5">
  <BackButton/>
  <h1>{t('Gebruiker')} {
      <Link to={`/users/${userWithLendings.id}/edit`} tooltip={t('Aanpassen')} style={{
        position: 'relative',
        top: -17,
        fontSize: '0.7em',
        left: 3}}>
        <FaEdit/>
      </Link>}</h1>
  <Table striped bordered hover>
  <thead>
  <tr>
    <th>{t('Alias')}</th><td>{userWithLendings.name}</td>
  </tr>
  <tr>
    <th>{t('Contact')}</th><td>{!userWithLendings.contact?t('Verborgen owv privacy'):userWithLendings.contact}</td>
  </tr>
  </thead>
  </Table>
  {Object.keys(lendings).filter((l) => lendings[l].length > 0).map((l) => (
    <>
    <h3>{l}</h3>
    <LendingOverview lendings={lendings[l]}/>
    </>
  ))}



  {false && JSON.stringify(userWithLendings)}
  </div>
}


export async function loader({ params }) {
  if(getCurrentUser()){
    return await getUserWithLendings(params.userId);
  }
  return await getBasicUser(params.userId);
}

export async function action({ params, request }) {
  const formData = await request.formData();

  console.log('User form data:')
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }

  const game = await getBoardGame(formData.get("gameId"));
  if (!game) throw new Response("", { status: 404 });

  const returnForm = formData.get("returnForm");
  if(returnForm){
    await returnBoardGame(game);
    return redirect(`/users/${params.userId}`);
  }

}
