import React, { useRef, useState } from 'react';
import { useLoaderData, Form, redirect, Link, useSubmit, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserWithLendings } from "../dataAccess/UsersDA";
import { returnBoardGame, getBoardGame } from "../dataAccess/BoardGameDA";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import {LendingOverview} from '../components/LendingOverview'
import HomePageButton from '../components/HomePageButton'
import {BackButton} from '../components/BackButton'
import {SuccessNotification} from '../components/SuccessNotification'
import NewUserFields, {validateNewUserForm} from '../components/NewUserFields'
import { createUser, updateUser, getBasicUser} from "../dataAccess/UsersDA";

export default function UserEdit() {
  //const {user} = useLoaderData();
  const {user} = useLoaderData();
  const [submitting, setSubmitting] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [formData, setFormData] = useState({
    name : user?.name || '',
    contact : user?.contact || '',
  });
    const submit = useSubmit();
    let { userId } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const stored = searchParams.get("ts");;

  const { t } = useTranslation();

  let formRef = useRef();

  const validationAndSubmit = async (e) => {
    setSubmitting(true);
      console.log(formData, userId);
      if(userId){

      }else{
        const validationResult = await validateNewUserForm(formData);
        if(!validationResult.ok){
          setValidationError(validationResult)
          setSubmitting(false);
          return;
        }
      }
    try{
      submit(formRef.current);
      setValidationError({});
    }catch(err){
      setValidationError({f : err});
    }
    setSubmitting(false);
  }

  return <>
  {false && JSON.stringify(user)}
  <Row className="m-2">
    <Col lg={2} xs={0}/>
    <Col xs={12} lg={6}>
      <HomePageButton variant="secondary"/>
    </Col>
  </Row>
  <Row className="m-2">
  <Col lg={3} xs={0}/>
  <Col xs={12} lg={6}>
  {userId && !user?.name && <Alert variant="danger">{t('Gebruiker niet gevonden')}</Alert>}
  {(!userId || user?.name) && <Form method="post" id="newUserForm" ref={formRef}>
  <NewUserFields formData={formData} setFormData={setFormData} validationError={validationError} />
  <div>
  <Button variant="primary" type="button" form="newUserForm" onClick={async () => {await validationAndSubmit()}} disabled={submitting}>
    {t('Opslaan')}
  </Button>
  </div>
  {validationError.f && <Alert variant="danger">{validationError.f}</Alert>}
  </Form>}
  </Col>
  </Row>
  {stored && <Row className="m-2">
  <Col lg={3} xs={0}/>
  <Col xs={12} lg={6}>
   <SuccessNotification message={t('Gebruikers gegeven opgeslagen...')} stored={stored} />
   </Col>
   </Row>}
  </>
}

export async function createUserAction({ params, request }) {
  const formData = await request.formData();

  console.log('User form data:')
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }

  const name = formData.get("name");
  const contact = formData.get("contact");
  const userId = await createUser({name, contact})
  return redirect(`/users/${userId}/edit?ts=${Date.now()}`)
}


export async function updateUserAction({ params, request }) {
  try{
    const formData = await request.formData();
    let {userId} = params;
    console.log('User form data:')
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    const name = formData.get("name");
    const contact = formData.get("contact");

    await updateUser({name, contact, userId})
    return redirect(`/users/${userId}/edit?ts=${Date.now()}`)
  } catch (error) {
    // Handle errors (optional: log the error for debugging)
    console.error("Error loading data:", error);
    throw new Response("Failed to load data", { status: 500 });
  }
}


export async function loader({ params }) {
  try {
    console.log("getBasicUser", params.userId)
      // Fetch game and users in parallel to reduce wait time
    const [user] = await Promise.all([
        getBasicUser(params.userId)
      ]);
    return {
      user
    };
  } catch (error) {
    // Handle errors (optional: log the error for debugging)
    console.error("Error loading data:", error);
    throw new Response("Failed to load data", { status: 500 });
  }
}
