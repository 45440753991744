import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

export const SuccessNotification  = ({ message, stored }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
    const timer = setTimeout(() => setShow(false), 4000);
    return () => clearTimeout(timer);
  }, [stored, setShow]);

  return (
    <Alert show={show} variant="success">
      {message}
    </Alert>
  );
};

export default SuccessNotification ;
