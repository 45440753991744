import React, { useEffect, useState } from "react";

import {dataAccess} from './dataAccess/dataAccess.js'

import { useTranslation } from "react-i18next";

const gamesDAO = dataAccess('boardgames')
// App.js

function BoardGameOverview() {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {


    // Function to fetch data from the database
    const fetchData = () => {
      gamesDAO.getAll(setData);
    };

    // Fetch data when the component mounts
    fetchData();
  }, []);
  if(data === null){
    return <div>{t('Bezig met laden')}</div>
  }
  return (
    <div>
      <h1>{t('Spellen')}</h1>
      <ul>
        {data.map((item, index) => (
          <li key={index}>{JSON.stringify(item)}</li>
        ))}
      </ul>
    </div>
  );
}

export default BoardGameOverview;
