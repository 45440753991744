
import { useTranslation } from "react-i18next";

export const PlayersAmount = ({minPlayers, maxPlayers, className }) => {
    const { t } = useTranslation();

    return <span className={className} dangerouslySetInnerHTML={{__html:minPlayers === maxPlayers?
      t(maxPlayers === 1?'Voor <strong>{{aantal}}</strong> speler': 'Voor <strong>{{aantal}}</strong> spelers', {
        aantal : maxPlayers > 10?'10+':maxPlayers
      }):
      t('Van <strong>{{start}}</strong> tot <strong>{{end}}</strong> spelers', {
        start: minPlayers,
        end: maxPlayers > 10?'10+':maxPlayers})}}/>
}

export const PlayersAmountShort = ({minPlayers, maxPlayers, className }) => {
    const { t } = useTranslation();

    return <span className={className} dangerouslySetInnerHTML={{__html:minPlayers === maxPlayers?
      t('{{aantal}} p', {
        aantal : maxPlayers > 10?'10+':maxPlayers
      }):
      t('<strong>{{start}}</strong> - <strong>{{end}}</strong> p', {
        start: minPlayers,
        end: maxPlayers > 10?'10+':maxPlayers})}}/>
}

export const PlayersAmountReactive = ({minPlayers, maxPlayers }) => {
  return (
    <>
      <PlayersAmount minPlayers={minPlayers} maxPlayers={maxPlayers} className="d-none d-sm-inline"/>
      <PlayersAmountShort minPlayers={minPlayers} maxPlayers={maxPlayers} className="d-inline d-sm-none"/>
    </>
  )
}

export default PlayersAmount
