
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";
import { formatRelative } from 'date-fns'
import { formatWithOptions } from 'date-fns/fp'
import { nl } from 'date-fns/locale'
import { Form } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ReturnLended from './ReturnLended'

export const LendingOverview = ({lendings }) => {

  const { t } = useTranslation();
  const dateToString = formatWithOptions({ locale: nl }, 'd MMMM yyyy')
  if(lendings.length === 0)
    return false;
  return <Table striped bordered hover>
    <thead>
      <tr>
        <th>{t('Spel')}</th>
        <th>{t('Wanneer')}</th>
        {!lendings[0].returned && <th>{t('Actie')}</th>}
      </tr>
    </thead>
    <tbody>
    {lendings.map((item, i) => (
      <tr key={item.id} id={item.id}>
        <td>{item.gameName}</td>
        <td>{!item.returned &&
          formatRelative(new Date(item.createdAt), new Date(), { locale: nl })}
          {item.returned &&
            t('Tussen {{start}} en {{end}}', {
              start : dateToString(new Date(item.createdAt)),
              end : dateToString(new Date(item.updatedAt))
            })}
        </td>
        {!item.returned && <td>
          <ReturnLended gameId={item.gameId} lendingId={item.id}/>
          </td>}
      </tr>
    ))
    }

    </tbody>
  </Table>
}
