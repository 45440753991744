import { useLoaderData, Link, Outlet, useNavigate } from "react-router-dom";
import { getBoardGames } from "../dataAccess/BoardGameDA";
import NavBar from "../components/NavBar"
import ErrorComponent from "../components/ErrorComponent"
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Modified loader function to handle errors
export async function loader() {
  try {
    const games = await getBoardGames();
    return { games };
  } catch (error) {
    return { error: 'Failed to load games. Please try again later.' };
  }
}

export default function Root() {
  const { games, error } = useLoaderData();
  const { t } = useTranslation();
  const [loginErrorMsg, setLoginErrorMsg] = useState(null);

  // Check if there's an error while loading games
  if (error) {
    return (
      <ErrorComponent error={error} />
    );
  }

  // Check if the games are still loading or empty
  if (!games || games.length === 0) {
    return (
      <div>
        <p>{t('loading')}</p> {/* or a custom message */}
      </div>
    );
  }

  return (
    <>
      <NavBar games={games} />
      <Outlet />
    </>
  );
}
