
import { useTranslation } from "react-i18next";



export const GameDifficulty = ({min, max, difficulty }) => {
    const { t } = useTranslation();

    function translatedDifficulty(d){
      if(d < 1.25){
        return t('Zeer eenvoudig');
      }
      if(d < 1.99){
        return t('Eenvoudig');
      }
      if(d < 3){
        return t('Gemiddeld');
      }
      if(d < 4){
        return t('Ingewikkeld');
      }
      return t('Zeer complex');
    }
    const minTranslatedDifficulty = translatedDifficulty(min)
    const maxTranslatedDifficulty = translatedDifficulty(max)
    if(minTranslatedDifficulty === maxTranslatedDifficulty || !max || difficulty){
      return translatedDifficulty(difficulty || min)
    }
    return t('Van {{s}} tot {{e}}', {
      s : translatedDifficulty(min),
      e : translatedDifficulty(max)
    });
}

export default GameDifficulty
