
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';

export const ErrorComponent = ({error }) => {
    const { t } = useTranslation();

    return   <div>
        <Alert>{error}</Alert> {/* Display the error message */}
      </div>;
}

export default ErrorComponent
