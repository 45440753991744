import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormControl, FormGroup, FormLabel, InputGroup } from 'react-bootstrap';

import { FaSearch } from 'react-icons/fa';
import './AutosuggestStyles.css';

import { useTranslation } from "react-i18next";

const AutoSuggestTextField = ({suggestions, inputPlaceHolder, onSuggestionSelected, clearTextAfterSelect, suggestionRenderer}) => {
  const { t } = useTranslation();
   const [value, setValue] = useState('');
    const [currentSuggestion, setCurrentSuggestion] = useState(null);

   // When the user types in the input field
  const onChange = (event, { newValue }) => {
    setValue(newValue);

    if(!clearTextAfterSelect && onSuggestionSelected &&
      (!newValue || (currentSuggestion && currentSuggestion.name !== newValue))){
      onSuggestionSelected(null, {suggestion:null});
    }
  };
  // Input props for the Autosuggest component
 const inputProps = {
   placeholder: inputPlaceHolder,
   value,
   onChange
 };
 const [suggestionsList, setSuggestionsList] = useState([]);

// Function to determine which suggestions to show
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : suggestions.filter(
      suggestion => suggestion.name.toLowerCase().includes(inputValue)
    );
  };
 // How to render each suggestion
 const renderSuggestion = suggestionRenderer?suggestionRenderer:(suggestion) => (
   <div key={suggestion.id}>
     {suggestion.name}
   </div>
 );

 // Fetch suggestions when input value changes
const onSuggestionsFetchRequested = ({ value }) => {
  setSuggestionsList(getSuggestions(value));
};

// Clear suggestions when input loses focus or is cleared
const onSuggestionsClearRequested = () => {
  setSuggestionsList([]);
};
 return (

     <Autosuggest
       suggestions={suggestionsList}
       onSuggestionsFetchRequested={onSuggestionsFetchRequested}
       onSuggestionsClearRequested={onSuggestionsClearRequested}
       getSuggestionValue={suggestion => suggestion.name}
       renderSuggestion={renderSuggestion}
       inputProps={inputProps}
       onSuggestionSelected={(event, {suggestion}) => {
         setCurrentSuggestion(suggestion);
         onSuggestionSelected(suggestion);
         if(clearTextAfterSelect){
           setValue('')
         }
       }}
       renderInputComponent={(inputProps) =>
         <InputGroup>
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
          <FormControl {...inputProps} />
        </InputGroup>
      }
     />
 );
}

export default AutoSuggestTextField;
