import React, { useRef } from 'react';
import { useLoaderData, Form, redirect, Link, useSubmit } from "react-router-dom";
import { returnBoardGame, getBoardGame,
   lendBoardGame, getAllUsers,
   getLendingDetails, deleteBoardGame } from "../dataAccess/BoardGameDA";
import  { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import BootstrapForm from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Fade from 'react-bootstrap/Fade';

import SearchBox from '../components/AutoSuggestTextField';
import PlayersAmount from '../components/PlayersAmount'
import ReturnLended from '../components/ReturnLended'
import RemoveBoardGame from '../components/RemoveBoardGame'
import GameDifficulty from '../components/GameDifficulty'
import HomePageButton from '../components/HomePageButton'
import {BackButton} from '../components/BackButton'
import NewUserFields, {validateNewUserForm} from '../components/NewUserFields'
import {getCurrentUser} from '../authentication/auth'
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function BoardGame() {
  const {game, users, lendingDetails} = useLoaderData();
  const [showLendModal, setShowLendModal] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [formData, setFormData] = useState({
  name : '',
  contact : ''
});
let formRef = useRef();

  const submit = useSubmit();

  const [userId, setUserId] = useState(null);
  //this function to close the dialog on page reload after submit
  useEffect(() => {
    setShowLendModal(false);
    setShowNewUser(false);
    setUserId(null);
  }, [setShowLendModal, game, setShowNewUser, setUserId])
  const { t } = useTranslation();
  if(!game || !users){
    return false;
  }
  const lendingValidationAndSubmit = async (e) => {
    if(showNewUser){
      console.log(formData);
      const validationResult = await validateNewUserForm(formData);
      if(!validationResult.ok){
        setValidationError(validationResult)
        return;
      }
    }
    submit(formRef.current);
  }
  return (
    <>
      <Container>
      <Row className="m-2">
        <Col lg={2} xs={0}/>
        <Col xs={12} lg={6}>
          <BackButton/>
        </Col>
      </Row>
      <Row>
        <Col lg={3} xs={0}/>
        <Col xs={12} lg={6} className="h2">
        {game.name} {getCurrentUser() &&
            <Link to={`/games/${game.id}/edit`} tooltip={t('Aanpassen')} style={{
              position: 'relative',
              top: -17,
              fontSize: '0.7em',
              left: 3}}>
              <FaEdit/>
            </Link>}
        </Col>
      </Row>
      <Row>
      <Col lg={3} xs={0}/>
      <Col xs={12} lg={6}>
          <Container>
          {game.type && game.type.length > 0 && <Row>
            <Col>{t('Categorie')}</Col>
            <Col>{game.type.map(t => <Badge style={{marginRight : 5}} bg="info" text="dark">{t}</Badge>)}</Col>
          </Row>}
          {game.mechanism && game.mechanism.length > 0 && <Row>
            <Col>{t('Mechanisme')}</Col>
            <Col>{game.mechanism.map(t => <Badge style={{marginRight : 5}} bg="warning" text="dark">{t}</Badge>)}</Col>
          </Row>}
          <Row>
            <Col><PlayersAmount minPlayers={game.min_amount_players} maxPlayers={game.max_amount_players}/></Col>
          </Row>
          <Row>
            <Col>{t('Moeilijkheidsgraad')}</Col>
            <Col><strong>{game.difficulty}</strong></Col>
          </Row>
          <Row>
            <Col>{game.link && <div><a href={game.link} target="gameLinkTarget">{game.link}</a></div>}</Col>
          </Row>
          </Container>
        </Col>
        <Col lg={6} style={{textAlign : 'center'}}>
          {game.pictureURL && <img src={game.pictureURL} width={300}/>}
        </Col>
      </Row>
      {game.lending && <Row style={{marginTop : 30}}>
        <Col lg={3} xs={0}/>
        <Col xs={12} lg={6}>
          {t('Uitgeleend door ')}<Link to={`/users/${lendingDetails?.user?.id}`}>{lendingDetails?.user?.name}</Link>
        </Col>
      </Row>}
      <Row style={{marginTop : 30}}>
      <Col lg={3} xs={0}/>
      <Col xs={12} lg={6}>
      {!game.lending && <Button variant="primary" onClick={() => setShowLendModal(true)}>{t('Uitlenen')}</Button>}
      {game.lending && <ReturnLended gameId={game.id}/>}{' '}
      {getCurrentUser() && <RemoveBoardGame gameId={game.id}/>}
      </Col>
      </Row>
      </Container>



        <Modal show={showLendModal} onHide={() => setShowLendModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Uitlenen')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form method="post" id="lendForm" ref={formRef}>
            <Collapse in={!showNewUser} timeout={500} >
            <div>
              <SearchBox
                suggestions={users}
                inputPlaceHolder={t('Kies je gebruikersnaam')}
                onSuggestionSelected={(suggestion) => {
                  if(suggestion?.id){
                    setUserId(suggestion.id);
                  }else{
                    setUserId(null);
                  }
                }}
                />
                <input name="userId" value={userId} type="hidden" />
                <Collapse in={userId === null} timeout={500}>
                <div>
                  <hr />
                  <Button
                    onClick={() => {
                      setShowNewUser(true);
                      setUserId(null);
                    }} variant="secondary"
                    style={{marginTop:10}}
                  >{t('Nieuwe gebruiker')}</Button>
                  </div>
                </Collapse>
                </div>
              </Collapse>
            {showNewUser && <Collapse in={showNewUser} timeout={1000} unmountOnExit>
              <div>
                <Button
                  onClick={() => setShowNewUser(false)} variant="secondary"
                  style={{marginTop:10}}
                >{t('Terug')}</Button><br/>
                <NewUserFields formData={formData} setFormData={setFormData} validationError={validationError}/>
                <br/>
                <br/>
              </div>
            </Collapse>}

          </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" form="lendForm" disabled={!userId && !showNewUser} onClick={async () => {await lendingValidationAndSubmit()}}>
              {t('Uitlenen')}
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}

export async function loader({ params }) {
  try {
      // Fetch game and users in parallel to reduce wait time
    const [game, users] = await Promise.all([
        getBoardGame(params.gameId),
        getAllUsers()
      ]);
    if (!game) throw new Response("", { status: 404 });
    let lendingDetails;
    if(game.lending){
      lendingDetails = await getLendingDetails(game.lending);
    }
    return {
      game,
      users,
      lendingDetails
    };
  } catch (error) {
    // Handle errors (optional: log the error for debugging)
    console.error("Error loading data:", error);
    throw new Response("Failed to load data", { status: 500 });
  }
}

export async function action({ params, request }) {
  try {
    const formData = await request.formData();

    console.log('Boardgame form data:')
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

      const game = await getBoardGame(params.gameId);
      if (!game) throw new Response("", { status: 404 });

    const returnForm = formData.get("returnForm");
    if(returnForm){
      await returnBoardGame(game);
      return redirect(`/games/${params.gameId}`);
    }

    const removeBoardGameForm = formData.get("removeBoardGame");
    if(removeBoardGameForm){
      if(game.lending){
        await returnBoardGame(game);
      }
      await deleteBoardGame(game.id);
      console.log('redirect to /')
      return redirect(`/`);
    }


    const userId = formData.get("userId");
    const name = formData.get("name");
    const contact = formData.get("contact");
    if(!userId && (!name || !contact)){
      return false;
    }
    await lendBoardGame(game, {name, contact, userId});
    return redirect(`/games/${params.gameId}`);
  } catch (error) {
    // Handle errors (optional: log the error for debugging)
    console.error("Error loading data:", error);
    throw new Response("Failed to load data", { status: 500 });
  }
}
