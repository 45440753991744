
import { useLoaderData, Form, redirect, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllUsersWithContact } from "../dataAccess/UsersDA";
import Table from 'react-bootstrap/Table';

export default function UsersOverview() {
  const usersWithContact = useLoaderData();
  const { t } = useTranslation();

  return <div className="m-5">
  <h1>{t('Gebruikers')}</h1>
    {usersWithContact && <Table striped bordered hover>
      <thead>
        <tr>
          <th>{t('Alias')}</th>
          <th>{t('Contact')}</th>
          <th>{t('Openstaande uitleningen')}</th>
          <th>{t('Actie')}</th>
        </tr>
      </thead>
      <tbody>
      {usersWithContact.map((item, i) => (
        <tr key={item.id} id={item.id}>
          <td>{item.name}</td>
          <td>{item.contact}</td>
          <td>{!item.amountLendings || item.amountLendings === 0?t('Geen'):item.amountLendings}
          </td>
          <td><Link to={`/users/${item.id}`}>{t('Details')}</Link></td>
        </tr>
      ))
      }

      </tbody>
    </Table>}
  </div>
}


export async function loader({ params }) {
  return await getAllUsersWithContact();
}

export async function action({ params, request }) {
  const formData = await request.formData();

  console.log('Boardgame form data:')
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }

}
