import { Form, redirect,useLoaderData } from "react-router-dom";
import { createBoardGame, updateBoardGame } from "../dataAccess/BoardGameDA";
import Button from 'react-bootstrap/Button';
import BootstrapForm from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import EditableSelect from "../components/EditableSelect";
import ErrorComponent from "../components/ErrorComponent"
import { getBoardGames } from "../dataAccess/BoardGameDA";
import  { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import {initCap, arrayContains, getUniqueValues} from '../utils'
////////////////////////////////////////////////////////////////////////////////

const defaultFormData = {
  name : '',
  type : [],
  mechanism : [],
  difficulty : '',
  min_amount_players : '',
  max_amount_players : '',
  link : '',
  pictureURL : ''
}

export default function NewBoardGame() {
  const {types, game, mechanisms, error} = useLoaderData();
  const { t } = useTranslation();
  const [formData, setFormData] = useState(game);

  useEffect(() => {
    setFormData(game);
  }, [game, setFormData])

  const onChangeFormData = (property) => (e) => {
    setFormData({...formData, [property] : e.target.value});
  }
  const rowStyle = {marginBottom : 10}

  if(error){
    return (
      <ErrorComponent error={error} />
    );
  }
  if(!formData){
    return <>
      No form data
      {JSON.stringify(game)}
    </>
  }

  return <Form method="post">
      <Container>
      <Row>
        <Col className="h1">
        {(game?.name)?t('Spel aanpassen') : t('Nieuw spel')}
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="gameName">{t('Naam')}</BootstrapForm.Label>
        </Col>
        <Col>
          <BootstrapForm.Control
            type="text"
            id="gameName"
            name="name"
            required
            value={formData.name}
            onChange={onChangeFormData('name')}
          />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="gameType">{t('Categorie')}</BootstrapForm.Label>
        </Col>
        <Col>
          <Typeahead
            id="gameType"
            multiple
            onChange={(type) => setFormData({...formData, type})}
            options={types}
            placeholder={t('=== Kies één of meerdere Categorieën ===')}
            selected={formData.type}
          />
          <input type="hidden" name="type" value={formData.type?.join(";")} />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="mechanisms-selector">{t('Mechanisme')}</BootstrapForm.Label>
        </Col>
        <Col>
          <Typeahead
            id="mechanisms-selector"
            multiple
            onChange={(mechanism) => setFormData({...formData, mechanism})}
            options={mechanisms}
            placeholder={t('=== Kies één of meerdere Mechanismes ===')}
            selected={formData.mechanism}
            allowNew
          />
          <input type="hidden" name="mechanism" value={formData.mechanism?.join(";")} />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="gameDifficulty">{t('Moeilijkheidsgraad')}</BootstrapForm.Label>
        </Col>
        <Col>
          <BootstrapForm.Control
            type="text"
            id="gameDifficulty"
            name="difficulty"
            required
            type="number"
            step=".01"
            minValue="1"
            maxValue="5"
            value={formData.difficulty}
            onChange={onChangeFormData('difficulty')}
          />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="gameMinAmountPlayers">{t('Aantal spelers')}</BootstrapForm.Label>
        </Col>
        <Col>
        <div className="d-flex align-items-center">
          <BootstrapForm.Control
            type="text"
            id="gameMinAmountPlayers"
            name="min_amount_players"
            required
            type="number"
            minValue="1"
            value={formData.min_amount_players}
            onChange={onChangeFormData('min_amount_players')}
            className="me-2"
          />
          <div className="me-2">
          {t('tot')}
          </div>
          <BootstrapForm.Control
            type="text"
            id="gameMaxAmountPlayers"
            name="max_amount_players"
            required
            type="number"
            minValue="1"
            value={formData.max_amount_players}
            onChange={onChangeFormData('max_amount_players')}
          />
          </div>
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="gameLink">{t('Link')}</BootstrapForm.Label>
        </Col>
        <Col>
          <BootstrapForm.Control
            type="text"
            id="gameLink"
            name="link"
            value={formData.link}
            onChange={onChangeFormData('link')}
          />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <BootstrapForm.Label htmlFor="gameLink">{t('URL foto')}</BootstrapForm.Label>
        </Col>
        <Col>
          <BootstrapForm.Control
            type="text"
            id="pictureURL"
            name="pictureURL"
            value={formData.pictureURL}
            onChange={onChangeFormData('pictureURL')}
          />
        </Col>
      </Row>
      <Row style={rowStyle}>
        <Col xs={2}>
          <Button variant="primary" type="submit">
            {t('Opslaan')}
          </Button>
        </Col>
      </Row>
      </Container>
    </Form>
}

function mapFormData(gameData, formData){
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
    if(key === 'type' || key === 'mechanism'){
      gameData[key] = value.split(';').map(s => s.trim()).filter(s => s.length > 0);
    }else{
      gameData[key] = value;
    }
  }
  return gameData
}


export async function newGameAction({ request }) {
  const formData = await request.formData();
  console.log('createBoardGame action formData is:', formData);
  let gameData = mapFormData({}, formData);
  
  console.log(gameData)
  const game = await createBoardGame(gameData);
  return redirect(`/games/${game.id}`);
}

export async function updateGameAction({ params, request }) {
  const formData = await request.formData();
  console.log('createBoardGame action formData is:', formData);
  let gameData = mapFormData({
    id : params.gameId
  }, formData)

  console.log(gameData)
  await updateBoardGame(gameData);
  return redirect(`/games/${params.gameId}`);
}


export async function loader({ params }) {
  let game = defaultFormData;
  try {
    const games = await getBoardGames();
    if(params.gameId){
      game = games.find((g) => g.id === params.gameId)
    }

    return {
      types : getUniqueValues(games, 'type'),
      mechanisms : getUniqueValues(games, 'mechanism'),
      game : game?game: defaultFormData
    }
  } catch (error) {
    return { error: 'Failed to load data. Please try again later.', game };
  }
}
