import {  Form } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import Collapse from 'react-bootstrap/Collapse';
import  { useState, useEffect } from "react";

export const ReturnLended = ({ gameId, lendingId}) => {
    const { t } = useTranslation();
    const [showYes, setShowYes] = useState(false);
    return <Form method="post" id="returnForm" style={{display:'inline'}}>
      <input type="hidden" name="returnForm" value="true" />
      <input type="hidden" name="gameId" value={gameId} />
      <input type="hidden" name="lendingId" value={lendingId} />
      {!showYes && <Button variant="primary" type="button" onClick={(e) => {setShowYes(true)}}>{t('Terugbrengen')}</Button>}
      <Collapse in={showYes} timeout={500} >
        <div style={{padding : 5}}>
          {t('Zeker?')} &nbsp;
          <Button variant="primary" type="submit">{t('Ja')}</Button>
          &nbsp;
          <Button variant="secondary" type="button" onClick={(e) => {setShowYes(false)}}>{t('Nee')}</Button>
        </div>
      </Collapse>
    </Form>;
}

export default ReturnLended
