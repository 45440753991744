const config = {
  apiKey: "AIzaSyANCZd_eK4EHJGy-9jgHOFPczGXM8xC5Qc",
  authDomain: "bah-bib-prod.firebaseapp.com",
  databaseURL: "https://bah-bib-prod-default-rtdb.europe-west1.firebasedatabase.app",  // Updated URL
  projectId: "bah-bib-prod",
  storageBucket: "bah-bib-prod.appspot.com",
  messagingSenderId: "392293021683",
  appId: "1:392293021683:web:f2569f353a40fb68578486",
  measurementId: "G-6TX5WNQLDE"
}

export default config;
