
import { useTranslation } from "react-i18next";
import GameDifficulty from './GameDifficulty'
import {PlayersAmountReactive} from '../components/PlayersAmount'
import { useLoaderData, redirect, Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';

export const InlineBoardGame = ({game }) => {
    const { t } = useTranslation();

    return <>
    {game.lending && <>&#10060;</>}{
        ' '
      }<Link to={`/games/${game.id}`}>{game.name}
      </Link>{
        ' - '
      }<PlayersAmountReactive
        minPlayers={game.min_amount_players} maxPlayers={game.max_amount_players}
      />{game.type && game.type.length > 0 && ' - '
      }{game.type && game.type.map(t => <Badge style={{marginRight : 5}} bg="info" text="dark">{t}</Badge>)}{
        game.mechanism && game.mechanism.length > 0 && ' - '
      }{game.mechanism?.map(t => <Badge style={{marginRight : 5}} bg="warning" text="dark">{t}</Badge>)}{
        ' - '
      }<GameDifficulty difficulty={game.difficulty}/></>;
}

export default InlineBoardGame
