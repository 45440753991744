
import { useLoaderData, Form, redirect, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserWithLendings } from "../dataAccess/UsersDA";
import { returnBoardGame, getBoardGame } from "../dataAccess/BoardGameDA";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {LendingOverview} from '../components/LendingOverview'
import HomePageButton from '../components/HomePageButton'
import {BackButton} from '../components/BackButton'

export default function Rules() {

  const { t } = useTranslation();
  return <>
  <Row className="m-2">
    <Col lg={2} xs={0}/>
    <Col xs={12} lg={6}>
      <BackButton/>
    </Col>
  </Row>
  <Row className="m-2">
  <Col lg={3} xs={0}/>
  <Col xs={12} lg={6}>

  <h1>{t('Regels voor de BaH spelbibliotheek')}</h1>
  <ol>
    <li>
Respect voor het spel en respect voor andere gebruikers:
<ol type="a">
<li>Zorg voor alle onderdelen van het spel</li>
<li>Ontbreekt er iets of is er iets kapot? Meld het: <a href="mailto:bordspelavondherent@gmail.com">bordspelavondherent@gmail.com</a></li>
<li>Deze spellen zijn voor ons allemaal, laten we ervoor zorgen dat ze zo lang mogelijk meegaan!</li>
</ol>
    </li>
    <li>
Er zijn geen 'harde' deadlines om een spel terug te sturen, maar:
<ol type="a">
<li>Probeer een spel binnen twee maanden terug te brengen (en maximaal 3 maanden na uitleendatum)</li>
<li>Problemen met de timing van het terugbrengen naar onze bordspelavonden? We zijn er voor jou: <a href="mailto:bordspelavondherent@gmail.com">bordspelavondherent@gmail.com</a> </li>
</ol>
</li>

<li>
Veel plezier met de spellen! Vertel het verder en verspreid de liefde voor bordspellen!
<ol type="a">
<li>
Kunnen we iets doen om hiermee te helpen? Aarzel dan niet om contact met ons op te nemen!
</li>
</ol>
</li>
</ol>
  <HomePageButton />

  </Col>
  </Row>
  </>
}
