import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from "react-i18next";
import  { useState, useEffect } from "react";
import {doSignIn, initAuth, doSignOut} from '../authentication/auth';
import SearchBox from '../components/AutoSuggestTextField';
import {PlayersAmountShort} from '../components/PlayersAmount';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaUser, FaSignOutAlt } from "react-icons/fa";

export const NavBar = ({games }) => {

    const [user, setUser] = useState(null);
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize navigate
    const location = useLocation();

    useEffect(() => {
      initAuth(setUser)
    }, [setUser]);
     useEffect(() => {
       setOffCanvasOpen(false);
     }, [location]);

    return <Navbar expand={"lg"} className="bg-body-tertiary mb-3">
      <Container fluid>
        <Navbar.Brand href="/"><img src="/logo192.png" height={50}/></Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar`} onClick={() => setOffCanvasOpen(true)}/>
        <Navbar.Offcanvas
              id={`offcanvasNavbar`}
              aria-labelledby={`offcanvasNavbarLabel`}
              placement="end"
              show={offCanvasOpen}
              onHide={() => setOffCanvasOpen(false)}
            >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel`}>
                  {t('Menu')}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3"  variant="pills">
                <Nav.Item>
                  <SearchBox
                     suggestions={games}
                     inputPlaceHolder={t('Vind een spel in onze bib')}
                     onSuggestionSelected={(suggestion) => {
                       if(suggestion?.id){
                         setOffCanvasOpen(false);
                         navigate(`/games/${suggestion.id}`);
                       }
                     }}
                     suggestionRenderer={(suggestion) => (
                       <div key={suggestion.id}>
                         {suggestion.lending && <>&#10060;</>}
                         {suggestion.name} (<PlayersAmountShort
                          minPlayers={suggestion.min_amount_players}
                          maxPlayers={suggestion.max_amount_players}
                          />)
                       </div>
                     )}
                     clearTextAfterSelect={true}
                     />
                     </Nav.Item>
                     <Nav.Item>
                       <Link to="rules"><Button  variant="secondary">{t('BaH bib regels')}</Button></Link>
                     </Nav.Item>
                     <Nav.Item>
                       <Link to="newUser"><Button>{t('Nieuwe gebruiker')}</Button></Link>
                     </Nav.Item>
                     {!user && <Nav.Item>
                     <Button variant="outline-dark" onClick={() => {
                       doSignIn();
                     }}>
                    <FaUser /> {t('Admin Login')}
                    </Button>
                    </Nav.Item>}
                    {user && <NavDropdown title={t('Admin sectie')} id="nav-admin">
                      <NavDropdown.Item>
                        <Link to="users">{t('Gebruikers')}</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link to="new">{t('Spel toevoegen')}</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link to="import">{t('Spellen importeren')}</Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                    }
                    {user && <Nav.Item>
                    <Button variant="outline-dark" onClick={() => {
                      setUser(null)
                      doSignOut();
                      navigate('/')
                    }}>
                   <FaSignOutAlt />
                   </Button>
                   </Nav.Item>}
                </Nav>
              </Offcanvas.Body>

        </Navbar.Offcanvas>

      </Container>
    </Navbar>
}

export default NavBar
