import { Form, redirect } from "react-router-dom";
import { importBoardGames } from "../dataAccess/BoardGameDA";

////////////////////////////////////////////////////////////////////////////////
export default function ImportBoardGames() {
  return (
    <Form method="post">
      <p>
        <label htmlFor="content">Data</label>
        <br />
        <textarea name="content" rows="10" cols="30" id="content" />
      </p>
      <p>
        <button type="submit">Save</button>
      </p>
    </Form>
  );
}

export async function action({ request }) {
  const formData = await request.formData();
  console.log('ImportBoardGames form data:')
  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
  const contentString = formData.get("content");
  if(contentString){
    const content = JSON.parse(contentString)
    if(Array.isArray(content)){
      console.log("importing games")
      try{

        await importBoardGames(content);
      }catch(err){
        console.error(err);
      }
      return redirect(`/`);
    }else{
      console.error("content is not an array")
    }
  }else{
    console.error("no content found")
  }
  return redirect(`/import`);
}
