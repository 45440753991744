import {dataAccess, map} from './dataAccess.js'
import {getCurrentUser} from '../authentication/auth'
const usersDAO = dataAccess('/users')
const usersContactDAO = dataAccess('/usersContact')
const lendingDAO = dataAccess('/lending')
const gamesDAO = dataAccess('/boardgames')

export async function getAllUsersWithContact() {
  const [users, contacts] = await Promise.all([
      usersDAO.getAll(),
      usersContactDAO.getAll()
    ]);
  if(!users || !contacts) return []
  //combine users and contacts
  return Object.keys(users).map(key => {
    return { ...users[key], ...contacts[key], id: key};
  });
}

export async function getUserWithLendings(userId){
  const [user, contact, lendings, games] = await Promise.all([
      usersDAO.getById(userId),
      usersContactDAO.getById(userId),
      lendingDAO.filter('userId', userId),
      gamesDAO.getAll()
    ]);

    return {...user, ...contact, id : userId, lendings : map(lendings).map((lending) => (
      {...lending, gameName : games[lending.gameId]?.name}
    ))}
}

export async function getBasicUser(userId){
  const fetchers = [usersDAO.getById(userId)]

  console.log('getCurrentUser()', getCurrentUser());
  if(getCurrentUser()){
    fetchers.push(usersContactDAO.getById(userId))
  }else{
    fetchers.push(async () => ({}))
  }
  const [user, contact] = await Promise.all(fetchers);

    return {...user, ...contact, id : userId}
}

export async function createUser({name, contact}){
  let createUserResult = await usersDAO.create({name});
  let userId = createUserResult.key;
  await usersContactDAO.update({contact, id : userId});
  return userId;
}


export async function updateUser({name, contact, userId}){
  await usersDAO.update({name, id : userId});
  await usersContactDAO.update({contact, id : userId});
  return userId;
}
