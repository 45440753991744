import app from "./firebase_init.js"; // Assuming the correct path to your configuration file
import { getDatabase, ref, onValue, get, child, push, serverTimestamp, update, set, query, orderByChild, equalTo } from "firebase/database";



export function map(results){
  if (!results) return [];
  return Object.keys(results).map(key => {
    return { ...results[key], id: key};
  });
}
// Initialize the Firebase database with the provided configuration
export const database = getDatabase(app);

export function dataAccess(collectionName){

  // Reference to the specific collection in the database
  const collectionRef = ref(database, collectionName);

  return {
    getAll : async () => {
      const snapshot = await get(collectionRef);

      if (snapshot.exists()) {
        return snapshot.val();
      }
      console.log("No data available");
      return null;
    },
    filter : async (filterKey, filterData) => {
      const filterQuery = query(collectionRef, orderByChild(filterKey), equalTo(filterData));
      const snapshot = await get(filterQuery);
      console.log('Filter snapshot', snapshot)
      if (snapshot.exists()) {
        return snapshot.val();
      }
      console.log("No data available");
      return null;
    },
    getById : async (id) => {
      const snapshot = await get(child(collectionRef, `${id}`));
      if (snapshot.exists()) {
          return snapshot.val();
      } else {
        console.log("No data available");
      }
      return null;
    },
    importData : async (arr) => {
      try {
        // Create an array of promises
        const promises = arr.map(async (o) => {
          try {
            await push(collectionRef, o);
            console.log("Object added successfully!", o);
          } catch (error) {
            console.error("Error adding object:", o, error); // Catch and log error for individual push
          }
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        console.log("All objects have been added!");
      } catch (error) {
        console.error("Error adding objects:", error);
      }
    },
    create : async (data) => {
      return await push(collectionRef, {
        ...data, createdAt: serverTimestamp()
      });
    },
    update : async (data) => {
      const id = data.id;
      if(!id) throw Error('Id not defined in update')
      delete data.id;
      return await set(ref(database, collectionName + '/' + id), {
        ...data, updatedAt: serverTimestamp()
      });
    }


  }


}
