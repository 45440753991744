import {  Form } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import Collapse from 'react-bootstrap/Collapse';
import  { useState, useEffect } from "react";

export const RemoveBoardGame = ({ gameId}) => {
    const { t } = useTranslation();
    const [showYes, setShowYes] = useState(false);
    return <Form method="post" id="removeBoardGame" style={{display: 'inline'}}>
      <input type="hidden" name="removeBoardGame" value="true" />
      <input type="hidden" name="gameId" value={gameId} />
      {!showYes && <Button variant="danger" type="button" onClick={(e) => {setShowYes(true)}}>{t('Verwijderen')}</Button>}
      <Collapse in={showYes} timeout={500} >
        <div style={{padding : 5}}>
          {t('Zeker dat je deze wilt verwijderen? Dit kan niet ongedaan gemaakt worden.')} &nbsp;
          <br/>
          <Button variant="primary" type="submit">{t('Ja')}</Button>
          &nbsp;
          <Button variant="secondary" type="button" onClick={(e) => {setShowYes(false)}}>{t('Nee')}</Button>
        </div>
      </Collapse>
    </Form>;
}

export default RemoveBoardGame
