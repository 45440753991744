import { GoogleAuthProvider } from "firebase/auth";
import app from "../dataAccess/firebase_init.js";
import { getAuth, getRedirectResult, signInWithPopup, onAuthStateChanged, signOut} from "firebase/auth";

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

provider.setCustomParameters({
  'login_hint': 'bordspelavondherent@gmail.com'
});

export const doSignIn = (errorCallback) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...


        console.log('getAuthUser', user)
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      if(errorCallback){
        errorCallback({errorCode, errorMessage})
      }else{
        console.error('getAuthUser failed', errorCode, errorMessage);
      }
    });

}

export const initAuth = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if(callback)
      callback(user)
  });
}

export const doSignOut = () => {
  signOut(auth);
}

export const getCurrentUser = () => (
  auth.currentUser
)
