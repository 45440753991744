
import { useLoaderData, redirect, Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import  { useState } from "react";
import { getAllUsersWithContact } from "../dataAccess/UsersDA";
import Table from 'react-bootstrap/Table';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {PlayersAmountReactive, PlayersAmount} from '../components/PlayersAmount'
import GameDifficulty from '../components/GameDifficulty'
import InlineBoardGame from '../components/InlineBoardGame'

import {arrayContains, getUniqueValues} from '../utils'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Alert from 'react-bootstrap/Alert';

const SearchBoardGame  = () => {
  const {games, error} = useLoaderData();
  const { t } = useTranslation();
  const maxAmountPlayers = games.reduce((max, game) => Math.min(Math.max(max, game.max_amount_players), 11), 0);
  const [playersAmount, setPlayersAmount] = useState([1, maxAmountPlayers]);
  const maxDifficulty = Math.ceil(games.reduce((max, game) => Math.max(max, game.difficulty), 0));
  const [gameDifficulty, setGameDifficulty] = useState([1, maxDifficulty]);
  const [gameTypes, setGameTypes] = useState([]);
  const [gameMechanisms, setGameMechanisms] = useState([]);
  const filteredGames = games.filter((g) => (
    (playersAmount[0] <= g.max_amount_players && (playersAmount[1] > 10 || g.max_amount_players <= playersAmount[1]))
    //(g.min_amount_players <= playersAmount[1] && (g.max_amount_players >= playersAmount[1] || (playersAmount[1] > 10 && playersAmount[0] === 0)) && (g.min_amount_players <= playersAmount[0] || playersAmount[0] === 0))
    //(g.min_amount_players <= playersAmount[1] && g.max_amount_players >= playersAmount[0])
    &&
    gameDifficulty[0] <= g.difficulty && g.difficulty <= gameDifficulty[1] &&
    arrayContains(g, gameTypes, "type") &&
    arrayContains(g, gameMechanisms, "mechanism")
  ))
  //const types = new Set(games.map(item => item.type.trim().toLowerCase())).map(initCap);
  const types = getUniqueValues(games, "type");
  const mechanisms = getUniqueValues(games, "mechanism");
  if (error) {
    return (
      <div>
        <Alert>{error}</Alert> {/* Display the error message */}
      </div>
    );
  }

  return <>
  <Container>
  <Row>
    <Col className="h1">
    {t('BaH! - Bib')}
    </Col>
  </Row>
  <Row>
    <Col className="h4">
    {t('Zoek een spel')}
    </Col>
  </Row>
  <Row>
    <Col xs={12} md={2}>{t('Categorie')}</Col>
    <Col>
    <Typeahead
      id="gameTypes-selector"
      multiple
      onChange={setGameTypes}
      options={types}
      placeholder={t('=== Kies één of meerdere Categorieën ===')}
      selected={gameTypes}
    />
    </Col>
  </Row>
  <Row style={{marginTop : 10}}>
    <Col xs={12} md={2} style={{paddingTop : 10}}>{t('Mechanisme')}</Col>
    <Col>
    <Typeahead
      id="mechanisms-selector"
      multiple
      onChange={setGameMechanisms}
      options={mechanisms}
      placeholder={t('=== Kies één of meerdere Mechanismes ===')}
      selected={gameMechanisms}
    />
    </Col>
  </Row>
  <Row style={{marginTop : 20}}>
    <Col xs={12} md={2}>{t('Aantal spelers')}</Col>
    <Col><RangeSlider min={1} max={maxAmountPlayers} value={playersAmount} onInput={setPlayersAmount}/></Col>
  </Row>
  <Row>
    <Col xs={12} md={2}></Col>
    <Col><PlayersAmount minPlayers={playersAmount[0]} maxPlayers={playersAmount[1]}/></Col>
  </Row>

  <Row style={{marginTop : 20}}>
    <Col xs={12} md={2}>{t('Moeilijkheidsgraad')}</Col>
    <Col><RangeSlider min={1} max={maxDifficulty} value={gameDifficulty} onInput={setGameDifficulty} step={0.1}/></Col>
  </Row>
  <Row>
    <Col xs={12} md={2}></Col>
    <Col><GameDifficulty min={gameDifficulty[0]} max={gameDifficulty[1]}/></Col>
  </Row>
</Container>
  <hr />
  <Container>
  <Row>
    <Col className="h2">
      {filteredGames.length === 0?t('Geen spel gevonden'):t('Gevonden spellen')}
    </Col>
  </Row>
  <Row>
    <Col>
    <div  style={{maxHeight:500, overflowY: 'scroll'}}>
    <Table striped bordered hover>
    <tbody>
    {Array.isArray(filteredGames) && filteredGames.map((game) => (
      <tr key={game.id}><td>
        <InlineBoardGame game={game} />
      </td></tr>
    ))}
    </tbody>
    </Table>
    </div>
    </Col>
  </Row>
  </Container>

  </>
}


export default SearchBoardGame
