import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root, { loader as rootLoader } from "./routes/root";
import SearchBoardGame from "./routes/searchBoardGame";
import BoardGameEditor, { newGameAction, updateGameAction, loader as gameEditorLoader } from "./routes/boardGameEditor";
import ImportBoardGames, { action as importBoardGamesAction } from "./routes/importBoardGames";
import UsersOverview, { loader as usersLoader } from "./routes/usersOverview";
import User, { action as returnBoardGameForUserAction, loader as userLoader } from "./routes/user";
import NewUser, { createUserAction, updateUserAction, loader as basicUserLoader } from "./routes/newUser";
import Rules from "./routes/rules";
import BoardGame, {
  loader as gameLoader,
  action as gameAction,
} from "./routes/boardgame";

let router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: rootLoader,
    children: [
      {
        path: "/",
        element: <SearchBoardGame />,
        loader: rootLoader
      },
      {
        path: "new",
        element: <BoardGameEditor />,
        action: newGameAction,
        loader: gameEditorLoader
      },
      {
        path: "newUser",
        element: <NewUser />,
        action: createUserAction,
        loader: basicUserLoader
      },
      {
        path: "games/:gameId/edit",
        element: <BoardGameEditor />,
        action: updateGameAction,
        loader: gameEditorLoader
      },
      {
        path: "rules",
        element: <Rules />,
      },
      {
        path: "import",
        element: <ImportBoardGames />,
        action: importBoardGamesAction,
      },
      {
        path: "users",
        element: <UsersOverview />,
        loader: usersLoader,
      },
      {
        path: "users/:userId",
        element:<User/>,
        action: returnBoardGameForUserAction,
        loader: userLoader,
      },
      {
        path: "users/:userId/edit",
        element:<NewUser/>,
        action: updateUserAction,
        loader: basicUserLoader,
      },
      {
        path: "games/:gameId",
        element: <BoardGame />,
        loader: gameLoader,
        action: gameAction,
        errorElement: <h2>Oops foutje</h2>,
      },
    ],
  },
]);

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function App() {
  return <RouterProvider router={router} />;
}
