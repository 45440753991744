export function initCap(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function trim(str){
  return str?.trim();
}

export function getUniqueValues(games, v){
  return [...new Set(games.flatMap(game => game[v]?[
    ...game[v].map(trim)
  ]:[]))].sort()
}

export function arrayContains(game, selectionArray, v){
  return (selectionArray.length === 0 || (game[v] && selectionArray.every(type => game[v].map(trim).includes(type))))
}
