import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button';

export const HomePageButton = ({ variant}) => {
    const { t } = useTranslation();

    return <Link to="/"><Button variant={variant}>{t('Start pagina')}</Button></Link>;
}

export default HomePageButton
